<template>
    <div class="main leader-show-main">
        <QsNavbar title="领导力思维专区"></QsNavbar>
        <div class="q-box mb-12 q-box-article">
            <div class="title ta-c">{{article.title}}</div>
            <div class="meta d-flex jc-between">
                <span>文章分类：{{article.cate_name}}</span>
                <span>{{article.create_time}}  发布</span>
            </div>
            <div class="body-y body-desc" v-html="article.zhai_yao"></div>
            <div class="body-y body-main" v-html="article.content" v-if="article.content"></div>
            <div class="footer ta-c" style="color:#999;" @click="onRegister" v-if="!userMeta.show_all">
                查看更多<van-icon name="arrow-down" />
            </div>
            <div class="footer ta-c" style="color:#999;" v-if="isSubscribe && isRegister">
                <!-- <a :href="'/client/Leader/pdf?id='+article.id" target="_blank">下载文章</a><van-icon name="down" /> -->
                <van-button plain hairline type="default" round  color="blue" size="small" :url="'/client/Leader/pdf?id='+article.id" icon="down">下载文章</van-button>
            </div>
        </div>
        <div class="q-box mb-12">
            <div class="header left">
                相关内容
            </div>
            <div class="body">
                <router-link :to="{path:'/leader/show',query:{id:item.id}}" tag="div" class="d-flex list-item article-box" v-for="item in related.list" :key="item.id" >
                    <div class="flex-1 mr">
                        <div>{{item.title}}</div>
                    </div>
                    <img :src="item.img"  class="thumb" width="120px" height="80px"/>
                </router-link>
            </div>
            <div class="footer ta-c" style="color:#999;font-size:14px;" @click="onLoadRelated" v-if="!related.finished">
                查看更多<van-icon name="arrow-down" />
            </div>
        </div>
        <div class="float-fav" v-if="isSubscribe">
            <van-icon :name="userMeta.faved?'star':'star-o'" size="32" @click="onArticleFav" :style="{'color':userMeta.faved?'#FFDA00':''}"/>
        </div>
        <!-- <div class="section panel">
            <div class="header left">
                <div class="title">热门评论</div>
            </div>
            <div class="body">
                <div class="d-flex comment-box b-b1" v-for="item in comment.list" :key="item.id">
                    <van-image
                        round
                        width="40px"
                        height="40px"
                        :src="item.head_pic"
                        />
                    <div class="flex-1 meta">
                        <p>{{item.nick_name}}</p>
                        <p class="c-gray">{{item.create_time}}</p>
                        <p v-html="item.content"></p>
                    </div>
                    <div class="actiom" @click="onCommentLike(item)">
                        <van-icon :name="item.liked?'good-job':'good-job-o'" /><span>{{item.like_quantity}}</span>
                    </div>
                </div>
                <div class="footer ta-c" @click="onLoadComment" v-if="!comment.finished">
                    查看更多<van-icon name="ellipsis" />
                </div>
            </div>
        </div>
         <div class="section panel" style="margin-bottom:80px;">
            <div class="header left">
                <div class="title">相关内容</div>
            </div>
            <div class="body">

            </div>
         </div>
         <div class="common-float ">
             <div class="d-flex">
                <van-field v-model="post.content" placeholder="写评论" disabled class="flex-1 qs-input"  @click="showComment=true;" />
                <van-icon :name="userMeta.liked?'good-job':'good-job-o'" size="20"  @click="onArticleLikes"/>
                <van-icon :name="userMeta.faved?'star':'star-o'" size="20" @click="onArticleFav"/>
                <van-icon name="share" size="20" @click="onShare"/>
             </div>
         </div>
         <van-popup class="popup-form"
            v-model="showComment"
            closeable
            close-icon="close"
            position="bottom">
                <div class="body">
                    <van-field
                    v-model="post.content"
                    rows="4"
                    type="textarea"
                    maxlength="500"
                    placeholder="请输入留言"
                    class="qs-input"
                    />
                    <div class="d-flex" style="justify-content: flex-end;">
                        <van-button type="primary" @click="onComment" size="small">提交</van-button>
                    </div>
                </div>
        </van-popup>
         -->
    </div>
</template>

<script>
import { mapState,mapGetters } from 'vuex'
import { Dialog } from 'vant';
export default {
    data(){
        return{
            id:0,
            article:{
                title:""
            },           
            userMeta:{liked:false,faved:false,show_all:false},
            related:{
                list:[],
                finished:false,
                post:{
                    aid:0,
                    page:1
                }
            },
            comment:{
                list:[],
                finished:false,
                post:{
                    aid:0,
                    page:1
                }
            },
            showComment:false,
            post:{
                article_id:0,
                content:''
            }
        }
    },
    mounted(){
        this.id = this.post.article_id = this.related.post.aid = this.comment.post.aid = this.$route.query.id;
        this.onLoad();
    },
    methods:{
        onLoad(){
            this.$ajax.get("client/leader/show?id="+this.id).then(res=>{
                this.article = res.data.article;
                this.comment.list = res.data.comments.data;
                this.related.list = res.data.relateds.data;
                this.userMeta = res.data.userMeta;
                if(res.data.comments.current_page>=res.data.comments.last_page){
                    this.comment.finished = true;
                }
                if(res.data.relateds.current_page>=res.data.relateds.last_page){
                    this.related.finished = true;
                }
            }).catch(e=>{
                console.log(e);
            });
        },
        onRegister(){
            if(!this.isSubscribe){
                Dialog.alert({
                    message: '请关注公众号后方可查看!',
                    theme: 'round-button',
                    className:"register-dialog",
                    closeOnClickOverlay:true,
                    confirmButtonColor:'#0099D8'
                })
                return;
            }
            Dialog.alert({
                message: '若想查看更多文章内容\n请前往验证升级',
                theme: 'round-button',
                className:"register-dialog",
                closeOnClickOverlay:true,
                confirmButtonColor:'#0099D8'
            }).then(() => {
                this.$router.push("/public/register");
            }).catch(e=>{
                console.log("cancel");
            });
        },
        onComment(){
            if(!this.post.content){
                return;
            }
           this.$ajax.post("client/user/postComment",this.post).then(res=>{
                this.$notify({ type: 'success', message: '留言成功!'});   
                this.showComment=false;
                this.comment.list.unshift(res.data);
            }).catch(e=>{});
        },
        onShare(){
            console.log("onShare");
        },
        onLoadComment(){
            this.comment.post.page++;
           this.$ajax.post("client/leader/comments",this.comment.post).then(res=>{
                this.comment.list = this.comment.list.concat(res.data.data,);
                if(res.data.current_page>=res.data.last_page){
                    this.comment.finished = true;
                }
            }).catch(e=>{});
        },
        onLoadRelated(){
            this.related.post.page++;
           this.$ajax.post("client/leader/relateds",this.related.post).then(res=>{
                this.related.list = this.related.list.concat(res.data.data);
                if(res.data.current_page>=res.data.last_page){
                    this.related.finished = true;
                }
            }).catch(e=>{});
        },
        onArticleLikes(){
            this.$ajax.post("client/user/leaderArticleLike",{aid:this.id}).then(res=>{
                this.userMeta.liked=!this.userMeta.liked;
                this.$notify({ type: 'success', message: '成功!'});    
            }).catch(e=>{})
        },
        onArticleFav(){
            this.$ajax.post("client/user/leaderArticleFav",{aid:this.id}).then(res=>{
                this.userMeta.faved=!this.userMeta.faved;
                this.$notify({ type: 'success', message: res.data});    
            }).catch(e=>{})
        },
        onCommentLike(item){
            this.$ajax.post("client/user/leaderCommentLike",{cid:item.id}).then(res=>{
                item.like_quantity += (item.like_quantity?-1:1);
                item.liked = !item.liked;   
                this.$notify({ type: 'success', message: '成功!'});          
            }).catch(e=>{})
        }
    },
    watch:{
        '$route'(to,from){
            this.id = this.post.article_id = this.related.post.aid = this.comment.post.aid = to.query.id;
            this.onLoad();
        }
    },
  computed:{
    ...mapGetters(['isLogin','isSubscribe','isRegister'])
  }
}
</script>

<style>
.body-main img,.body-main video{max-width:100% !important;}
</style>
<style lang="scss" scoped>

.q-box-article{
    padding-top:4px; overflow: hidden;line-height:24px;font-size:14px;
    .title{margin:4px 0;font-size: 24px;line-height: 34px;font-weight: 600;}
    .meta{font-size: 14px;color: #999999; }
    .body{color:#666666;font-size: 15px;}
    
    .body-desc{background:#EBEEF5;margin:8px 0;padding:8px;}
}



.comment-box{font-size: 14px;align-items: start;padding:8px 0;margin:8px 0;
    .meta{margin:0 8px;line-height: 20px;}
    }
.comment-box:last-child{margin:0;}
.common-float{
    position: fixed;bottom:0;width:100%;border-top:1px solid #ccc;background: #fff;
    .d-flex>i{margin:0 8px;}
    }
    .float-fav{width:56px;height:56px;background: #FFFFFF;box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.08);border-radius: 50%;    display: flex;align-items: center;justify-content: center;
        position: fixed;right: 18px;bottom: 50px;}
</style>
<style lang="scss">
.register-dialog .van-dialog__message{font-size: 15px;font-weight: $fw;}
</style>

